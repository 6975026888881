/****************************************************
 * Base Container (Wider Layout)
 ****************************************************/
 .thank-you-page {
  max-width: 1200px; /* Adjusted to match Shopify's width */
  margin: 0 auto;
  padding: 2rem 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  color: #212b36;
  line-height: 1.5;
}

/* Optional: Even wider on extra-large screens */
@media (min-width: 1400px) {
  .thank-you-page {
    max-width: 1400px;
  }
}

/****************************************************
 * Top Title Section
 ****************************************************/
.top-section {
  margin-bottom: 2rem;
  text-align: center;
}

.checkout-title {
  font-size: 2rem;
  margin: 0 0 1rem 0;
  font-weight: 600;
  color: #212b36;
}

/* Order header row */
.order-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-bottom: 1px solid #dfe3e8;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.checkmark-icon {
  width: 50px;
  height: 50px;
  color: #5c6ac4; /* Shopify's primary color */
}

.order-info-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-number {
  margin: 0;
  font-size: 1rem;
  color: #637381;
}

.thank-you-text {
  margin: 0.5rem 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #212b36;
}

.order-updates {
  margin: 0;
  font-size: 1rem;
  color: #637381;
}

/****************************************************
 * Main Content (two columns on desktop)
 ****************************************************/
.main-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Left column */
.left-column {
  flex: 0 0 60%;
}

/* Right column */
.right-column {
  flex: 0 0 40%;
}

/****************************************************
 * Section Titles
 ****************************************************/
.section-title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  font-weight: 600;
  color: #212b36;
}

/****************************************************
 * Map
 ****************************************************/
.map-container {
  margin-bottom: 1.5rem;
}

.map-container iframe {
  width: 100%;
  height: 250px; /* default height */
  border: 1px solid #dfe3e8;
  border-radius: 4px;
}

/* Increase map height on larger screens */
@media (min-width: 992px) {
  .map-container iframe {
    height: 350px;
  }
}

/****************************************************
 * Contact / Shipping Info
 ****************************************************/
.contact-shipping {
  background-color: #f9fafb;
  border: 1px solid #dfe3e8;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 4px;
}

.contact-label,
.address-label,
.payment-method-label {
  font-weight: 600;
  margin: 1rem 0 0.5rem;
  font-size: 1rem;
  color: #212b36;
}

.contact-value,
.address-value,
.payment-method-value {
  font-size: 1rem;
  margin: 0;
  color: #637381;
}

.confirmed-date {
  font-size: 0.9rem;
  margin-top: 1rem;
  color: #637381;
}

/****************************************************
 * Continue Shopping Button (Aligned Right)
 ****************************************************/
.continue-shopping-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #5c6ac4; /* Shopify's primary color */
  color: #fff;
  text-transform: uppercase;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  margin-left: auto;  /* Pushes the button to the right in a flex container */
  transition: background-color 0.2s ease;
}

.continue-shopping-button:hover {
  background-color: #3f4d9e; /* Darker shade for hover */
}

/****************************************************
 * Right Column: Order Items
 ****************************************************/
.order-item {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.item-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border: 1px solid #dfe3e8;
  border-radius: 4px;
  margin-right: 1.5rem;
}

.item-details {
  flex: 1;
}

.item-name {
  margin: 0;
  font-weight: 600;
  color: #212b36;
}

.item-price {
  margin: 0;
  font-weight: 500;
  color: #637381;
}

/****************************************************
 * Summary
 ****************************************************/
.summary {
  background-color: #f9fafb;
  border: 1px solid #dfe3e8;
  padding: 1.5rem;
  margin: 1.5rem 0;
  border-radius: 4px;
}

.summary-line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  font-size: 1rem;
  color: #637381;
}

.total-line {
  font-weight: 600;
  border-top: 1px solid #dfe3e8;
  padding-top: 0.75rem;
  margin-top: 0.75rem;
  color: #212b36;
}

/****************************************************
 * Payment Status (Removed)
 ****************************************************/
.payment-status {
  display: none;
}

/****************************************************
 * Error Style
 ****************************************************/
.error {
  color: #d32f2f; /* Shopify's error color */
  text-align: center;
  font-size: 1rem;
  margin-top: 1rem;
}

/****************************************************
 * Responsive: two-column on desktops, single-column on phones
 ****************************************************/
@media (min-width: 200px) {
  .main-content {
    flex-direction: row;
  }
}

@media (max-width: 500px) {
  .main-content {
    flex-direction: column;
  }
  .left-column,
  .right-column {
    flex: 0 0 auto;
    width: 100%;
  }
}
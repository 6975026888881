/* Reset and Base Styles */
body, html, #root {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  background: #fff;
  color: #000;
  overflow-x: hidden;
  box-sizing: border-box;
}
*, *::before, *::after {
  box-sizing: inherit;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* App Container */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #fff;
}

/* Header */
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background: #fff;
}
.logo {
  max-height: 100px;
}

/* Cart Container */
.cart-container {
  position: fixed;
  top: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  z-index: 3000;
}
.cart-button {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
}
.cart-icon {
  height: 40px;
  width: 40px;
}
.cart-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background: #ff4136;
  color: #fff;
  border-radius: 50%;
  font-size: 0.8rem;
  padding: 2px 6px;
  text-align: center;
}

/* Main Content */
.main {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

/* Products Container */
.products-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  justify-items: center;
}
.product-card {
  background: #fff;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-image {
  max-height: 70vh;
  object-fit: contain;
  width: 100%;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 0;
}
.modal-content {
  background: #fff;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
}

/* Spacer elements to add vertical space on mobile */
.modal-spacer {
  height: 60px;
  pointer-events: none;
}

/* Modal Body */
.modal-body {
  display: flex;
  flex: 1;
  gap: 2rem;
}
.modal-left, .modal-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.modal-left {
  align-items: center;
}
.modal-image {
  width: 100%;
  height: auto;
  max-width: 600px;
  max-height: 80vh;
  object-fit: contain;
}
.modal-right {
  align-items: flex-start;
  padding: 1rem;
}
.modal-right h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}
.modal-right p {
  margin: 0 0 1rem 0;
  font-size: 1rem;
}
.close-button {
  position: absolute;
  top: 40px;      /* Increase top to move it down */
  right: 40px;    /* Increase right to move it left from the edge */
  background: none;
  border: none;
  font-size: 2rem;
  color: #000;
  cursor: pointer;
  z-index: 10000;
}


/* Add to Cart Button */
.add-to-cart-button {
  background: #000;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
  max-width: 300px;
  width: 100%;
  margin-top: 0.5rem;
}
.add-to-cart-button:hover {
  background: #333;
}
.modal-right select {
  padding: 0.3rem;
  border: 1px solid #000;
  border-radius: 4px;
  background: #fff;
  color: #000;
  margin-bottom: 1rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  /* Modal layout: stack image on top */
  .modal-body {
    flex-direction: column;
  }
  /* Make modal image larger */
  .modal-image {
    max-width: 100%;
    max-height: 50vh;
  }
  /* Modify fixed button container to remove scroll lock */
  .modal-button-container {
    position: static;
    background: #fff;
    padding: 1rem;
    box-shadow: none;
    text-align: center;
  }
  .modal-button-container .add-to-cart-button {
    font-size: 1.2rem;
    padding: 1rem 2rem;
  }
}

@media (max-width: 768px) {
  /* Reduce the top margin of the add-to-cart button */
  .add-to-cart-button {
    margin-top: 0rem; /* Or even 0 if you want it snug */
  }
}


/* Responsive Adjustments for Products */
@media (max-width: 600px) {
  .products-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

/* iPhone-only media query (commonly used approach) */
@media only screen 
  and (max-device-width: 480px) 
  and (-webkit-min-device-pixel-ratio: 2) {

  /* Add white "boxes" by adding extra padding at top/bottom */
  .modal-content {
    padding-top: 3rem;   /* Adjust this value as needed */
    padding-bottom: 3rem;
  }

  /* Move the close button (X) further down */
  .close-button {
    top: 50px;  /* e.g., push it further down */
    right: 20px; /* or adjust further left if you like */
  }

  /* Ensure Add to Cart button sits above the bottom padding */
  .add-to-cart-button {
    margin-bottom: 2rem; /* Extra spacing from the bottom */
  }
}

/* =========================================================
   Admin Page Container and General Setup
========================================================= */

/* Centers the whole admin panel and sets a max width */
.admin-container {
  width: 95%;
  max-width: 1200px; 
  margin: 0 auto; 
  padding: 1.5rem;
}

.admin-container h1 {
  margin-bottom: 2rem;
  font-size: 2rem;
}

/* =========================================================
   Admin Login Styles
========================================================= */

/* Container for the login form */
.password-container {
  width: 95%;
  max-width: 400px;
  margin: 2rem auto; /* center the login box on the page */
  background: #fafafa;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
}

.password-container h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.password-container input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #c92828;
  border-radius: 5px;
  font-family: inherit;
  font-size: 1rem;
  box-sizing: border-box;
}

.password-container button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  transition: background 0.3s ease;
}

.password-container button:hover {
  background-color: #45a049;
}

/* =========================================================
   Add Product Section
========================================================= */

.add-product {
  background: #f5f5f5;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.add-product h3 {
  margin-bottom: 1rem;
  color: #333;
}

/* Inputs and Textareas */
.add-product input,
.add-product textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #c92828;
  border-radius: 5px;
  font-family: inherit;
  font-size: 1rem;
}

/* Image Upload Row */
.image-upload {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.image-upload input {
  flex: 1;
  padding: 10px;
  border: 1px solid #c92828;
  border-radius: 5px;
  font-family: inherit;
  font-size: 1rem;
}

.image-upload button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  transition: background 0.3s ease;
  font-size: 1rem;
}

.image-upload button:hover {
  background-color: #45a049;
}

/* Image Preview Grid */
.image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.admin-image-container {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 5px;
}


.admin-image-container {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 20px; /* Add some margin to ensure space for the trash can */
}

.remove-image {
  position: absolute;
  top: 1px;
  right: 1px;
  background-color: red;
  border: none;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Add Product Button */
.add-product-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  transition: background 0.3s ease;
}

.add-product-btn:hover {
  background-color: #45a049;
}

/* =========================================================
   Product List
========================================================= */

.product-list h3 {
  margin-bottom: 1rem;
  color: #333;
}

.product-item {
  display: flex;
  align-items: center;
  background: #fafafa;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 1rem;
  position: relative; /* For absolute positioning of the delete button */
}

.product-item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 1rem;
}

.product-details {
  flex: 1; /* Takes remaining space */
}

.product-details h4 {
  margin: 0;
  color: #333;
  font-size: 1.2rem;
}

.product-details p {
  margin: 0.3rem 0;
  color: #666;
  font-size: 1rem;
}

.delete-btn {
  background-color: #ff4d4d;
  border: none;
  color: white;
  padding: 8px 12px; /* Slightly larger padding for better click area */
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; /* Position the button absolutely within the product item */
  top: 1rem; /* Adjust as needed */
  right: 1rem; /* Adjust as needed */
}

.delete-btn:hover {
  background-color: #ff1a1a;
}

/* =========================================================
   Responsive Adjustments
========================================================= */

/* Small Devices (max-width: 480px) */
@media (max-width: 480px) {
  .admin-container {
    padding: 1rem;
  }

  .admin-container h1 {
    font-size: 1.5rem;
  }

  .password-container,
  .add-product {
    padding: 1rem;
  }

  .password-container h2 {
    font-size: 1.2rem;
  }

  .password-container button {
    font-size: 0.9rem;
    padding: 8px;
  }

  .admin-image-container {
    width: 80px;
    height: 80px;
  }

  .product-item img {
    width: 80px;
    height: 80px;
  }

  .product-item h4 {
    font-size: 1rem;
  }

  .product-item p {
    font-size: 0.9rem;
  }
}

/* Medium Devices (max-width: 768px) */
@media (max-width: 768px) {
  .admin-container {
    padding: 1rem;
  }

  .add-product {
    padding: 1rem;
  }

  /* Stack image upload vertically */
  .image-upload {
    flex-direction: column;
  }

  /* Center the image previews */
  .image-preview {
    justify-content: center;
  }

  /* Stack product item details */
  .product-item {
    flex-direction: column;
    align-items: flex-start;
  }

  /* Move delete button to the bottom or top as needed */
  .delete-btn {
    align-self: flex-end;
    margin-top: 0.5rem;
  }
}
/* Reset and Box-Sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Cart Page */
.cart-page {
  display: flex;
  padding: 2rem;
  background-color: #fff;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  position: relative;
}

/* Close (X) Button */
.close-cart {
  position: absolute;
  top: 0.75rem;
  right: 2rem;
  font-size: 1.75rem;
  background: none;
  border: none;
  color: #000;
  cursor: pointer;
  transition: color 0.2s ease;
  z-index: 10;
}

.close-cart:hover {
  color: #666;
}

/* Cart Title */
.cart-title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  width: 100%;
}

/* Cart Left Section */
.cart-left {
  flex: 2 1 65%;
}

/* Cart Items List */
.cart-items {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Cart Item */
.cart-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

/* Product Image */
.image-container {
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-item-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  object-fit: contain;
  max-height: 600px;
  display: block;
  margin: 0 auto;
}

/* Product Details Container */
.cart-item-details {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Product Name and Price Container */
.product-name-price-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

/* Product Title */
.product-title {
  font-size: 1.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-size {
  font-size: 0.9rem;
  color: #555;
  margin-top: 0.5rem;
}

/* Product Quantity */
.product-quantity {
  font-size: 0.9rem;
  color: #555;
  margin-top: 0.25rem;
}

/* Product Price */
.product-price {
  font-size: 1rem;
  color: #333;
  margin-left: 1rem;
}

/* Delete Selected Items Button */
.delete-item-button {
  background: none;
  border: none;
  padding: 3px;
  color: #ff4d4f;
  font-size: 2rem;
  cursor: pointer;
  transition: color 0.2s ease;
}

.delete-item-button:hover {
  color: #ff7875;
}

/* Cart Right Section - Order Summary */
.cart-right {
  flex: 1 1 30%;
  padding: 1.5rem;
}

/* Order Summary Heading */
.cart-right h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-align: center;
  white-space: nowrap;
}

/* Summary Table */
.summary-table {
  width: 100%;
  margin: 0 auto;
}

.summary-table tr {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.summary-table td {
  font-size: 1rem;
  padding: 0.2rem 0;
}

.summary-table td:first-child {
  text-align: left;
  flex: 1;
}

.summary-table td:last-child {
  text-align: right;
  flex: 0;
}

/* Buttons */
.checkout-button {
  display: block;
  margin: 2rem auto 0 auto;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.checkout-button:hover {
  background-color: #333;
}

.delete-button {
  display: block;
  margin: 1rem auto 0 auto;
  padding: 0.75rem;
  font-size: 1rem;
  color: #fff;
  background-color: #ff4d4f;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #ff7875;
}

/* Empty Cart Message */
.empty-cart-message {
  color: red;
  font-size: 1.5em;
  text-align: center;
  margin-top: 50px;
}

/* Empty Cart Link */
.empty-cart-link {
  display: inline;
  font-size: 1.5rem;
  color: #ff0000;
  text-decoration: underline;
  text-align: center;
  margin-top: 0;
  cursor: pointer;
  transition: color 0.2s ease;
}

.empty-cart-link:hover {
  color: #cc0000;
}

/* Responsive Design */

/* Medium Screens (Laptops/Tablets) */
@media (max-width: 1024px) {
  .cart-page {
    flex-direction: row;
    padding: 1.5rem;
  }

  .cart-left,
  .cart-right {
    flex: 1 1 50%;
  }
}

/* Small Screens (Tablets and below) */
@media (max-width: 768px) {
  .cart-page {
    flex-direction: column-reverse;
    padding: 1rem;
    gap: 1rem;
  }

  .cart-left,
  .cart-right {
    flex: 1 1 100%;
  }

  .checkout-button,
  .delete-button {
    font-size: 0.9rem;
  }

  .empty-cart-link {
    margin-top: 2rem;
    font-size: 1.3rem;
  }
}

/* Extra Small Screens (Mobile) */
@media (max-width: 480px) {
  .cart-page {
    gap: 1rem;
  }

  .cart-item {
    gap: 0.5rem;
  }

  .summary-table td {
    padding: 0.5rem 0;
  }

  .checkout-button {
    padding: 0.6rem;
    font-size: 0.85rem;
  }

  .empty-cart-link {
    font-size: 1.2rem;
    margin-top: 1.5rem;
  }
}
